<template>
    <div>
        <!-- <h1 class="text-uppercase text-center text-primary">Prochainement</h1> -->
        <b-card class="min-vh-100"
        >
            <b-row>
                <!-- <b-col sm="4">
                    <img class="img-fluid" src="@/assets/images/universign-logo.png" />
                </b-col> -->
                <b-col sm="4" class="text-center">
                    <h3 style="line-height:100px;height:100px;text-transform:uppercase;">Félicitations</h3>
                </b-col>
            </b-row>
            <hr>
            <b-row class="d-flex justify-content-center text-center">
                <b-col sm="4" md="3" lg="2">
                    <b-alert show variant="info" class="p-3 text-center" style="color:#000 !important;"><feather-icon class="text-body align-middle mr-25" style="color:#080 !important;" icon="CheckCircleIcon" size="50"/></b-alert>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-alert show variant="success" class="p-3 text-center" style="color:#000 !important;"> Nous vous remercions pour votre signature.<br/><br/> Notre partenaire Universign vous enverra dans quelques instants par mail un exemplaire signé de ce document.<br/><br/> N’hésitez pas à vérifier vos courriers indésirables !</b-alert>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BCard,BRow,BCol,BAlert } from 'bootstrap-vue'
export default {
    components: {
    BCard,
    BRow,
    BCol,
    BAlert
  },
}
</script>

<style>
.img-proche
{
    padding:5% 10%;
}
@media(max-width:768px)
{
    .img-proche
    {
        margin-top:50px;
        padding: 0;
    }
}
</style>